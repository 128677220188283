/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Notification as NotificationNotification } from '../models/notification/notification';
import { NotificationRequest as NotificationNotificationRequest } from '../models/notification/notification-request';
import { Notifier as NotificationNotifier } from '../models/notification/notifier';
import { PlantReport as NotificationPlantReport } from '../models/notification/plant-report';
import { Setting as NotificationSetting } from '../models/notification/setting';
import { Subscription as NotificationSubscription } from '../models/notification/subscription';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation createNotificationNotification
   */
  static readonly CreateNotificationNotificationPath = '/notifications/';

  /**
   * Create Notification will be used by SDS API.
   *
   * Create Notification and Push to Firebase
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNotificationNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationNotification$Response(params?: {
    /**
     * Body Notification request from SDS API
     */
    body?: NotificationNotificationRequest;
  }): Observable<StrictHttpResponse<Array<NotificationNotification>>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.CreateNotificationNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationNotification>>;
        }),
      );
  }

  /**
   * Create Notification will be used by SDS API.
   *
   * Create Notification and Push to Firebase
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNotificationNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationNotification(params?: {
    /**
     * Body Notification request from SDS API
     */
    body?: NotificationNotificationRequest;
  }): Observable<Array<NotificationNotification>> {
    return this.createNotificationNotification$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationNotification>>) => r.body as Array<NotificationNotification>),
    );
  }

  /**
   * Path part for operation getByIdNotificationNotification
   */
  static readonly GetByIdNotificationNotificationPath = '/notifications/{id}/';

  /**
   * Finds Notifications by ID.
   *
   * Returns Notifications based on ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByIdNotificationNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByIdNotificationNotification$Response(params: {
    /**
     * ID of the Notification
     */
    id: number;
  }): Observable<StrictHttpResponse<NotificationNotification>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetByIdNotificationNotificationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationNotification>;
        }),
      );
  }

  /**
   * Finds Notifications by ID.
   *
   * Returns Notifications based on ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByIdNotificationNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByIdNotificationNotification(params: {
    /**
     * ID of the Notification
     */
    id: number;
  }): Observable<NotificationNotification> {
    return this.getByIdNotificationNotification$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationNotification>) => r.body as NotificationNotification),
    );
  }

  /**
   * Path part for operation updateNotificationNotification
   */
  static readonly UpdateNotificationNotificationPath = '/notifications/{id}/';

  /**
   * Update the Notification to set the status.
   *
   * Updates an existing Notification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotificationNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationNotification$Response(params: {
    /**
     * Notification Id
     */
    id: number;

    /**
     * Body containing the modified data.
     */
    body?: NotificationNotification;
  }): Observable<StrictHttpResponse<NotificationNotification>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateNotificationNotificationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationNotification>;
        }),
      );
  }

  /**
   * Update the Notification to set the status.
   *
   * Updates an existing Notification
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNotificationNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationNotification(params: {
    /**
     * Notification Id
     */
    id: number;

    /**
     * Body containing the modified data.
     */
    body?: NotificationNotification;
  }): Observable<NotificationNotification> {
    return this.updateNotificationNotification$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationNotification>) => r.body as NotificationNotification),
    );
  }

  /**
   * Path part for operation getByPlantNotificationNotification
   */
  static readonly GetByPlantNotificationNotificationPath = '/plants/{id}/notifications/';

  /**
   * Get Notifications by Plant.
   *
   * Finds Notifications by the parent Plant id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByPlantNotificationNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByPlantNotificationNotification$Response(params: {
    /**
     * ID of Plant
     */
    id: string;

    /**
     * Number of results that should be returned.
     */
    maxResults?: number;

    /**
     * Get results from this Page.
     */
    page?: number;

    /**
     * The notification type list
     */
    type?: string;

    /**
     * The specified user id from PASS
     */
    userId: number;
  }): Observable<StrictHttpResponse<Array<NotificationNotification>>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetByPlantNotificationNotificationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('page', params.page, {});
      rb.query('type', params.type, {});
      rb.query('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationNotification>>;
        }),
      );
  }

  /**
   * Get Notifications by Plant.
   *
   * Finds Notifications by the parent Plant id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByPlantNotificationNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByPlantNotificationNotification(params: {
    /**
     * ID of Plant
     */
    id: string;

    /**
     * Number of results that should be returned.
     */
    maxResults?: number;

    /**
     * Get results from this Page.
     */
    page?: number;

    /**
     * The notification type list
     */
    type?: string;

    /**
     * The specified user id from PASS
     */
    userId: number;
  }): Observable<Array<NotificationNotification>> {
    return this.getByPlantNotificationNotification$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationNotification>>) => r.body as Array<NotificationNotification>),
    );
  }

  /**
   * Path part for operation getAllNotificationNotification
   */
  static readonly GetAllNotificationNotificationPath = '/user/{id}/notifications/';

  /**
   * Get all Notifications by page for User and Installer scopes.
   *
   * Returns all Notifications from the system that the user has access to. The Headers will include TotalElements, TotalPages, CurrentPage and PerPage to help with Pagination.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllNotificationNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllNotificationNotification$Response(params: {
    /**
     * User id for the user type or installer type.
     */
    id: number;

    /**
     * Number of results that should be returned.
     */
    maxResults?: number;

    /**
     * Current Page number
     */
    page?: number;

    /**
     * The notification type list
     */
    type?: string;
  }): Observable<StrictHttpResponse<Array<NotificationNotification>>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetAllNotificationNotificationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('page', params.page, {});
      rb.query('type', params.type, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationNotification>>;
        }),
      );
  }

  /**
   * Get all Notifications by page for User and Installer scopes.
   *
   * Returns all Notifications from the system that the user has access to. The Headers will include TotalElements, TotalPages, CurrentPage and PerPage to help with Pagination.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllNotificationNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllNotificationNotification(params: {
    /**
     * User id for the user type or installer type.
     */
    id: number;

    /**
     * Number of results that should be returned.
     */
    maxResults?: number;

    /**
     * Current Page number
     */
    page?: number;

    /**
     * The notification type list
     */
    type?: string;
  }): Observable<Array<NotificationNotification>> {
    return this.getAllNotificationNotification$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationNotification>>) => r.body as Array<NotificationNotification>),
    );
  }

  /**
   * Path part for operation createNotificationNotifier
   */
  static readonly CreateNotificationNotifierPath = '/notifiers/';

  /**
   * Create Notifier.
   *
   * Creates a new Notifier object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNotificationNotifier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationNotifier$Response(params?: {
    /**
     * The object data
     */
    body?: NotificationNotifier;
  }): Observable<StrictHttpResponse<NotificationNotifier>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.CreateNotificationNotifierPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationNotifier>;
        }),
      );
  }

  /**
   * Create Notifier.
   *
   * Creates a new Notifier object
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNotificationNotifier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationNotifier(params?: {
    /**
     * The object data
     */
    body?: NotificationNotifier;
  }): Observable<NotificationNotifier> {
    return this.createNotificationNotifier$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationNotifier>) => r.body as NotificationNotifier),
    );
  }

  /**
   * Path part for operation getByIdNotificationPlantReport
   */
  static readonly GetByIdNotificationPlantReportPath = '/plant-reports/{id}/';

  /**
   * Finds PlantReports by ID.
   *
   * Returns PlantReports based on ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByIdNotificationPlantReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByIdNotificationPlantReport$Response(params: {
    /**
     * ID of the PlantReport
     */
    id: number;
  }): Observable<StrictHttpResponse<NotificationPlantReport>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetByIdNotificationPlantReportPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationPlantReport>;
        }),
      );
  }

  /**
   * Finds PlantReports by ID.
   *
   * Returns PlantReports based on ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByIdNotificationPlantReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByIdNotificationPlantReport(params: {
    /**
     * ID of the PlantReport
     */
    id: number;
  }): Observable<NotificationPlantReport> {
    return this.getByIdNotificationPlantReport$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationPlantReport>) => r.body as NotificationPlantReport),
    );
  }

  /**
   * Path part for operation getByUserIdNotificationSetting
   */
  static readonly GetByUserIdNotificationSettingPath = '/notifiers/{userId}/settings/';

  /**
   * Find setting by the userId of the notifier.
   *
   * Get the user setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByUserIdNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByUserIdNotificationSetting$Response(params: {
    /**
     * User Id
     */
    userId: number;
  }): Observable<StrictHttpResponse<NotificationSetting>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetByUserIdNotificationSettingPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationSetting>;
        }),
      );
  }

  /**
   * Find setting by the userId of the notifier.
   *
   * Get the user setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByUserIdNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByUserIdNotificationSetting(params: {
    /**
     * User Id
     */
    userId: number;
  }): Observable<NotificationSetting> {
    return this.getByUserIdNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationSetting>) => r.body as NotificationSetting),
    );
  }

  /**
   * Path part for operation createNotificationSetting
   */
  static readonly CreateNotificationSettingPath = '/notifiers/{userId}/settings/';

  /**
   * Create Setting.
   *
   * Creates a new Setting object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNotificationSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationSetting$Response(params: {
    /**
     * User Id
     */
    userId: number;

    /**
     * The object data
     */
    body?: NotificationSetting;
  }): Observable<StrictHttpResponse<NotificationSetting>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.CreateNotificationSettingPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationSetting>;
        }),
      );
  }

  /**
   * Create Setting.
   *
   * Creates a new Setting object
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNotificationSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationSetting(params: {
    /**
     * User Id
     */
    userId: number;

    /**
     * The object data
     */
    body?: NotificationSetting;
  }): Observable<NotificationSetting> {
    return this.createNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationSetting>) => r.body as NotificationSetting),
    );
  }

  /**
   * Path part for operation updateNotificationSetting
   */
  static readonly UpdateNotificationSettingPath = '/settings/{id}/';

  /**
   * Update the Setting.
   *
   * Updates an existing Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotificationSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationSetting$Response(params: {
    /**
     * Setting Id
     */
    id: number;

    /**
     * Body containing the modified data.
     */
    body?: NotificationSetting;
  }): Observable<StrictHttpResponse<NotificationSetting>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateNotificationSettingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationSetting>;
        }),
      );
  }

  /**
   * Update the Setting.
   *
   * Updates an existing Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNotificationSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationSetting(params: {
    /**
     * Setting Id
     */
    id: number;

    /**
     * Body containing the modified data.
     */
    body?: NotificationSetting;
  }): Observable<NotificationSetting> {
    return this.updateNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationSetting>) => r.body as NotificationSetting),
    );
  }

  /**
   * Path part for operation updateComponentCommunicationNotificationSetting
   */
  static readonly UpdateComponentCommunicationNotificationSettingPath =
    '/settings/{id}/component-communication/{status}/';

  /**
   * Update the Component Communication in Setting.
   *
   * Update the Component Communication in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateComponentCommunicationNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateComponentCommunicationNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.UpdateComponentCommunicationNotificationSettingPath,
      'put',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Component Communication in Setting.
   *
   * Update the Component Communication in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateComponentCommunicationNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateComponentCommunicationNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateComponentCommunicationNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation updateDataTransferNotificationSetting
   */
  static readonly UpdateDataTransferNotificationSettingPath = '/settings/{id}/data-transfer/{status}/';

  /**
   * Update the Data Transfer in Setting.
   *
   * Update the Data Transfer in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDataTransferNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDataTransferNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateDataTransferNotificationSettingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Data Transfer in Setting.
   *
   * Update the Data Transfer in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDataTransferNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDataTransferNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateDataTransferNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation updateDeviationNotificationSetting
   */
  static readonly UpdateDeviationNotificationSettingPath = '/settings/{id}/deviation/{status}/';

  /**
   * Update the Deviation in Setting.
   *
   * Update the Deviation in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDeviationNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDeviationNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateDeviationNotificationSettingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Deviation in Setting.
   *
   * Update the Deviation in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDeviationNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDeviationNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateDeviationNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation updateOperatorReportNotificationSetting
   */
  static readonly UpdateOperatorReportNotificationSettingPath = '/settings/{id}/operator-report/{status}/';

  /**
   * Update the Operator Report in Setting.
   *
   * Update the Operator Report in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOperatorReportNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateOperatorReportNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateOperatorReportNotificationSettingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Operator Report in Setting.
   *
   * Update the Operator Report in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOperatorReportNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateOperatorReportNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateOperatorReportNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation updateComponentStatusNotificationSetting
   */
  static readonly UpdateComponentStatusNotificationSettingPath = '/settings/{id}/status-error/{status}/';

  /**
   * Update the Component Status in Setting.
   *
   * Update the Component Status in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateComponentStatusNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateComponentStatusNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.UpdateComponentStatusNotificationSettingPath,
      'put',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Component Status in Setting.
   *
   * Update the Component Status in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateComponentStatusNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateComponentStatusNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateComponentStatusNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation updateWeekendSilenceNotificationSetting
   */
  static readonly UpdateWeekendSilenceNotificationSettingPath = '/settings/{id}/weekend-silence/{status}/';

  /**
   * Update the Weekend Silence in Setting.
   *
   * Update the Weekend Silence in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWeekendSilenceNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateWeekendSilenceNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateWeekendSilenceNotificationSettingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Weekend Silence in Setting.
   *
   * Update the Weekend Silence in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWeekendSilenceNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateWeekendSilenceNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateWeekendSilenceNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation updateYieldSummaryNotificationSetting
   */
  static readonly UpdateYieldSummaryNotificationSettingPath = '/settings/{id}/yield-summary/{status}/';

  /**
   * Update the Yield Weekly Report in Setting.
   *
   * Update the Yield Weekly Report in Setting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateYieldSummaryNotificationSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateYieldSummaryNotificationSetting$Response(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateYieldSummaryNotificationSettingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Update the Yield Weekly Report in Setting.
   *
   * Update the Yield Weekly Report in Setting
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateYieldSummaryNotificationSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateYieldSummaryNotificationSetting(params: {
    /**
     * The settings id
     */
    id: number;

    /**
     * Determines the value for enabling/disabling a notification setting
     */
    status: boolean;
  }): Observable<void> {
    return this.updateYieldSummaryNotificationSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation createNotificationSubscription
   */
  static readonly CreateNotificationSubscriptionPath = '/subscription/';

  /**
   * Create Subscription.
   *
   * Creates a new Subscription object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNotificationSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationSubscription$Response(params?: {
    /**
     * The object data
     */
    body?: NotificationSubscription;
  }): Observable<StrictHttpResponse<NotificationSubscription>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationService.CreateNotificationSubscriptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationSubscription>;
        }),
      );
  }

  /**
   * Create Subscription.
   *
   * Creates a new Subscription object
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNotificationSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNotificationSubscription(params?: {
    /**
     * The object data
     */
    body?: NotificationSubscription;
  }): Observable<NotificationSubscription> {
    return this.createNotificationSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationSubscription>) => r.body as NotificationSubscription),
    );
  }
}
